// Variables
$border-radius-box: 8px;
$border-radius-input: 3px;
$border-radius-button: 50px;


// Colors
$dark-gray: #4A4A4A;
$light-gray: #979797;
$lightest-gray: #F5F7F9;
$apple-green: #37BD83;
$blue-color: #5780E9;
$gray-color: #87899C;
$dark-blue-color: #4D51FB;
$blue-gray-color: #3C4969;
$turquoise-color: #50E3C2;
$orange-color: #FC754B;
$yellow-color: #FAD500;
$brown-color: #AB6E50;
$red-color: #DF4E4E;
$purple-color: #5D47FC;
$dark-purple-color: #564d69;
$divider-color:	#E7E7E7;
$border-color: #cccccc;
$border-light-color: #eaedd6;
$error: #F03E41;
$menu-item-color: #f7f7f7;
$selected-color: #647f0b;
$light-green: #c6e120;
$ghost-white: #F9F8FE;
$lavender: #E5E8F6;
$table-hover-background: #F5F8E1;
$calendar-day-color: #6A6A6A;
$blue-gray: #E3E8F9;
$euronics-blue: #1D428A;
$euronics-text-blue: #2E6BAB;
$euronics-yellow: #F1C400;
$light-blue-background: #DFECFE;

$z-index-loading: 400;
$z-index-toast: 300;
$z-index-modal: 200;
$z-index-modal-background: 199;
$z-index-dropdown: 150;
$z-index-header: 100;
$z-index-error: 10;

// Mixins

@mixin  extra-small {
	@media only screen and (max-width: 576px) {
		@content; } }
@mixin  small {
	@media only screen and (max-width: 768px) {
		@content; } }

@mixin  medium {
	@media only screen and (max-width: 992px) {
		@content; } }

@mixin  large {
	@media only screen and (max-width: 1200px) {
		@content; } }

@mixin  extra-large {
	@media only screen and (min-width: 1200px) {
		@content; } }

// Color classes

.green {
	color: $apple-green !important; }
.blue {
	color: $blue-color !important; }
.turquoise {
	color: $turquoise-color !important; }
.orange {
	color: $orange-color !important; }
.yellow {
	color: $yellow-color !important; }
.red {
	color: $red-color !important; }
.dark-gray {
	color: $dark-gray !important; }

.green-bg {
	background: $apple-green !important; }
.blue-bg {
	background: $blue-color !important; }
.turquoise-bg {
	background: $turquoise-color !important; }
.orange-bg {
	background: $orange-color !important; }
.yellow-bg {
	background: $yellow-color !important; }
.red-bg {
	background: $red-color !important; }
.lightest-gray-bg {
	background: $lightest-gray !important; }
.gray-bg {
	background: $gray-color !important; }
.euronics-blue {
	background: $euronics-blue !important; }

// Default styles

* {
	box-sizing: border-box; }

html, body {
	font-family: 'Montserrat', sans-serif;
	font-size: 12px;
	background: #EFF1F4;
	width: 100%;
	padding: 0;
	margin: 0;
	color: #575a66; }

input::placeholder, textarea::placeholder {
	color: #9BACC7!important; }

a {
	text-decoration: none;
	cursor: pointer; }

h1,h2,h3,h4,h5 {
	font-family: 'Montserrat', sans-serif;
	width: 100%; }

h1 {
	font-size: 40px;
	font-weight: bold;
	text-align: left;
	line-height: 40px; }

h2 {
	font-size: 24px;
	line-height: 26px;
	color: #575a66; }

h3 {
	font-size: 22px;
	font-weight: 400;
	color: #1D232A;
	margin-bottom: 15px; }

h4 {
	font-size: 14px;
	font-weight: 700; }

ul {
	list-style-type: none;
	padding: 0;
	margin: 0; }

#app {
	overflow: hidden; }

strong {
	font-weight: 600; }

.text-right {
	text-align: right; }

.text-left {
	text-align: left; }

.text-center {
	text-align: center; }

p {
	font-size: 14px;
	line-height: 1.7; }

textarea {
	resize: none;
	font-family: 'Montserrat', sans-serif; }

input {
	font-family: 'Montserrat', sans-serif; }

input.ant-input, .ant-input-number, .ant-input-number input.ant-input-number-input {
	font-size: 14px;
	background: white;
	border-radius: 6px;
	border: 1px solid #d9d9d9; }

.panel input.ant-input, .panel .ant-input-number, .panel .ant-input-number input.ant-input-number-input {
	background: #F7F7F7; }

.ant-table input.ant-input, .ant-table .ant-input-number, .ant-table .ant-input-number input.ant-input-number-input {
	background: #F7F7F7; }

input.ant-input, input.ant-input-number-input {
	padding: 0 15px; }

.input-group-wrapper {
	display: flex; }

.container {
	width: 100%; }

.flex-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap {
  	width: 100%; }
	height: 100%; }

.form-container {
	width: 520px;
	padding: 30px;
	h2 {
		text-align: center; } }

.text-field {
	padding: 12px 24px; }

.campaign-card {
	width: 100%;
	color: #1F1F1F;
	border-radius: 12px;
	background: white;
	box-shadow: 12px 12px 20px rgba(219, 225, 232, 0.7);
	margin-bottom: 30px;
	position: relative;
	.campaign-remove {
		position: absolute;
		top: 10px;
		right: 10px;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background: rgb(231, 0, 82);
		color: white;
		font-size: 16px;
		z-index: 10;
		text-align: center;
		padding-top: 3px;
		opacity: 0;
		transition: opacity 200ms linear;
		cursor: pointer; }
	&:hover {
		box-shadow: 12px 12px 20px rgba(209, 215, 222, 1);
		.campaign-remove {
			opacity: 1; } }
	span {
		padding-left: 10px;
		font-size: 14px;
		font-weight: 600;
		color: #1F1F1F; }

	.campaign-views > div, .campaign-likes > div {
		padding: 15px 15px 0 15px;
		font-size: 20px;
		font-weight: 600;
		color: #28333A; }

	.campaign-views > small, .campaign-likes > small {
		font-size: 10px;
		padding: 0 0 0 15px;
		color: #838383;
		font-weight: 400; }
	.campaign-likes {
		text-align: right; }
	.campaign-cover {
		position: relative;
		height: 230px;
		width: 100%;
		border-radius: 12px 12px 0 0;
		background-position: center center;
		background-repeat: no-repeat;
		.cover-label {
			font-weight: bold;
			text-transform: uppercase;
			position: absolute;
			background: white no-repeat bottom 12px left 20px;
			background-size: 16px;
			top: 145px;
			left: -1px;
			padding: 10px 15px;
			border-radius: 0 12px 12px 0; } }
	.card-content {
		padding: 30px 20px 20px 20px;
		.campaign-title {
			height: 60px;
			overflow: hidden;
			margin-bottom: 10px; }
		h3 {
			color: black;
			font-size: 20px;
			font-weight: 600; }
		i {
			font-size: 18px;
			vertical-align: -2px;
			color: #A3B0B8; }
		.campaign-date, .campaign-time {
			margin-bottom: 10px;
			height: 22px; } }
	.card-button {
		text-align: center;
		padding: 15px;
		background-color: #5BB05F;
		color: white;
		border-radius: 0 0 12px 12px;
		text-transform: uppercase; } }

.category-heading {
	margin: 30px 0;
	h2 {
		font-weight: 400;
		font-size: 24px;
		color: #1D232A; } }

.dot {
	display: inline-block;
	border-radius: $border-radius-button;
	margin: 0 15px;
	height: 12px;
	width: 12px;
	vertical-align: 3px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	& + span {
		display: inline-block;
		vertical-align: middle; } }

.pull-left {
	float: left!important; }

.pull-right {
	float: right!important; }

.campaign-category {
	background: white;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	cursor: pointer;
	margin: 40px 0;
	&.inactive {
		opacity: 0.5;
		cursor: default; }
	.cover {
		background-size: cover;
		position: relative;
		height: 205px;
		background: #594C69 no-repeat center center;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		.icon {
			width: 100%;
			height: 100%;
			background: no-repeat center center;
			background-size: 60px 60px; } }
	.content {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		min-height: 220px;
		padding: 10px 20px;
		h2 {
			padding: 30px 15px 5px 15px;
			color: #5E5E5E;
			font-size: 22px;
			font-weight: bold;
			text-align: center; }
		h4 {
			padding: 0px 15px 5px 15px;
			color: #5E5E5E;
			font-size: 14px;
			font-weight: 600;
			margin-top: 5px;
			text-align: center; }
		p {
			text-align: center;
			line-height: 1.8;
			color: #909090; } } }

.date-range {
	margin-top: 39px;
	button.btn {
		font-size: 12px;
		padding: 10px 25px; } }


header {
	background: #564d69;
	background: -moz-linear-gradient(-45deg, #127ABE 0%, #144F9F 100%);
	background: -webkit-linear-gradient(-45deg, #127ABE 0%, #144F9F 100%);
	background: linear-gradient(273.03deg, #127ABE 0%, #144F9F 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#127ABE', endColorstr='#144F9F', GradientType=1);
	color: white;
	padding: 16px 40px;
	height: 60px;
	.logo {
		height: 45px;
		margin-top: -7px; }
	.main-menu {
		height: 30px;
		border-right: 1px solid rgba(255, 255, 255, 0.27);
		padding-right: 25px;
		float: left;
		li {
			display: inline-block;
			a {
				color: rgba(255, 255, 255, 0.54);
				font-size: 16px;
				font-weight: 600;
				padding: 2px 20px 5px 20px;
				opacity: 0.75;
				text-decoration: none;
				display: block; }
			&.active {
				a {
					color: white;
					font-weight: 600; } } } }
	.dropdown {
		float: left;
		margin-left: 35px;
		position: relative;
		.icon {
			font-size: 24px;
			line-height: 30px; } } }

button.btn, a.btn {
	cursor: pointer;
	min-width: 100px;
	background-color: white;
	font-size: 13px;
	color: #5E5E5E;
	font-weight: 600;
	outline: 0;
	padding: 12px 25px;
	border-radius: 4px;
	text-transform: uppercase;
	border: 1px solid #d4d4d4;
	letter-spacing: 0.5px;
	&.wide {
		width: 100%; }
	&.primary {
		color: white;
		outline: 0;
		border: 0;
		background: #144F9F;
		background: -moz-linear-gradient(-90deg, #127ABE 0%, #144F9F 100%);
		background: -webkit-linear-gradient(-90deg, #127ABE 0%, #144F9F 100%);
		background: linear-gradient(270deg, #127ABE 0%, #144F9F 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bc315a', endColorstr='#564d69', GradientType=1); }
	&.primary-blue {
		background: #DFECFE;
		color: #1D428A; }
	&.primary-yellow {
	  	color: white;
	  	outline: 0;
	  	border: 0;
  		background: #F1C400; }
	&.vertical {
		margin: 10px 0;
		width: 100%; } }


.button {
	&.wide {
		width: 100%; } }

.modal {
	position: fixed;
	padding: 0;
	transition: all 200ms linear;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	text-align: center;
	opacity: 0;
	visibility: hidden;
	z-index: $z-index-modal; }

.modal.shown {
	opacity: 1;
	visibility: visible; }

.modal:after {
	display: inline-block;
	height: 100%;
	margin-left: -.05em;
	content: ' ';
	vertical-align: middle; }

.modal:before {
	position: fixed;
	content: ' ';
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%);
	transition: all 200ms linear;
	z-index: $z-index-modal-background; }

.modal-wrapper {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	z-index: $z-index-modal-background;
	opacity: 0;
	visibility: hidden;
	margin: 20px;
	background: white;
	border-radius: 10px;
	width: calc(100% - 40px);
	max-width: 1400px;
	text-align: left;
	transform: scale(0.7);
	transition: opacity 200ms linear, visibility 200ms linear, transform 200ms linear;
	box-shadow: 0 0 100px rgba(0, 0, 0, 0.25); }

.modal.shown .modal-wrapper {
	opacity: 1;
	visibility: visible;
	transform: scale(1); }

.modal[data-size="s"] .modal-wrapper {
	max-width: 500px; }

.modal[data-size="m"] .modal-wrapper {
	max-width: 720px; }

.modal[data-size="l"] .modal-wrapper {
	max-width: 900px; }

.modal-header {
	padding: 25px 25px 25px 30px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	background: #144F9F;
	color: white;
	margin-bottom: 10px;
	font-size: 18px;
	font-weight: 400; }

.modal-header h3 {
	text-align: center;
	font-size: 22px;
	font-weight: 700;
	margin: 0;
	color: white; }

.modal-content {
	padding: 25px 25px 25px 30px;
	white-space: normal;
	font-size: 15px; }

.modal-footer {
	border-top: 1px solid $divider-color;
	padding: 25px 25px 25px 25px;
	text-align: right;
	background-color: $lightest-gray;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px; }

.modal-footer .button {
	margin-left: 20px; }



.select-option-title {
	font-weight: bold;
	padding-top: 3px; }

.select-option-text {
	font-size: 12px;
	padding-bottom: 3px;
	white-space: normal;
	line-height: 20px;
	margin-top: 5px; }

.disc-list {
	li {
		position: relative;
		margin-bottom: 15px;
		font-size: 14px;
		padding-left: 36px;
		line-height: 1.5;
		&:before {
			content: '';
			position: absolute;
			width: 21px;
			height: 30px;
			background: url('../public/images/check_blue_fill.svg') no-repeat center center;
			background-size: 20px;
			left: 0;
			top: -5px;
			border-radius: 100%; } } }

.form-steps {
	background: $lightest-gray;
	border-bottom: 1px solid #e1e1e1;
	padding: 40px;
	margin-bottom: 30px; }

.steps-action {
	padding: 100px 0 100px 0;
	button {
		min-width: 250px;
		width: auto; } }

.breadcrumbs {
	background: white;
	padding: 10px 0 12px 0;
	border-bottom: 1px solid $divider-color;
	h3 {
		padding-top: 2px;
		line-height: 1.6;
		font-size: 18px;
		text-transform: none;
		margin: 0;
		font-weight: 600; }
	ul {
		li {
			font-size: 13px;
			display: inline-block;
			a {
				font-size: 14px; } }
		li+li:before {
			padding-left: 7px;
			margin-right: 7px;
			content: '>'; } } }

.categories-list {
	li {
		position: relative;
		height: 135px;
		display: inline-block;
		text-align: center;
		margin-bottom: 10px; } }

.mockup {
	position: relative;
	margin: 0 auto;
	.mockup-bg {
		position: absolute;
		width: 352px;
		height: 706px;
		background-size: 353px auto;
		background-repeat: no-repeat;
		z-index: 8;
		&:after {
			position: absolute;
			content: '';
			background-image: url(../public/images/iphone-mockup.png);
			border-radius: 50px;
			width: 352px;
			height: 706px;
			background-size: 353px auto;
			background-repeat: no-repeat;
			z-index: 2; }
		&:before {
			position: absolute;
			content: '';
			background-image: url(../public/images/iphone-mockup-top.png);
			border-radius: 120px;
			width: 352px;
			height: 105px;
			top: 0px;
			left: -1px;
			background-size: 355px 50px;
			background-repeat: no-repeat;
			z-index: 9; } }
	.mockup-image {
		position: absolute;
		top: 20px;
		left: 23px;
		height: 260px;
		width: 307px;
		background: no-repeat center center;
		background-size: cover;
		content: '';
		overflow: hidden;
		.controls {
			text-align: center;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			margin: 0 auto;
			z-index: 3; }
		&.empty {
			background-image: url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/placeholder.png); }
		.has-error .ant-form-explain, .has-error .ant-form-split {
			font-size: 16px; } }
	.mockup-ribbon-content {
		color: #4A4A4A;
		position: absolute;
		top: 260px;
		left: 23px;
		text-align: left;
		background: white;
		padding: 5px 20px 2px 20px;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		z-index: 4;
		.mockup-ribbon-value {
			font-size: 22px;
			font-weight: bold; }
		.mockup-ribbon-sale-type {
			position: absolute;
			top: 7px;
			padding-left: 3px;
			font-size: 14px; } }
	.mockup-content {
		position: absolute;
		top: 330px;
		left: 38px;
		.mockup-title {
			font-size: 24px;
			line-height: 1.25;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			color: #4A4A4A;
			font-weight: bold;
			width: 290px;
			height: auto;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			position: relative;
			margin-bottom: 10px; }
		.mockup-distance {
			float: left;
			background: url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/icons/men_walking.svg) no-repeat left center;
			background-size: 12px;
			padding-left: 19px;
			height: 24px;
			padding-top: 5px;
			font-weight: bold;
			margin-right: 20px; }
		.mockup-remaining {
			float: left;
			background: url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/icons/clock_fill_grey.svg) no-repeat left top 2px;
			background-size: 16px;
			padding-left: 22px;
			height: 24px;
			padding-top: 5px;
			font-weight: bold; }
		.mockup-description {
			font-size: 14px;
			line-height: 1.5;
			display: -webkit-box;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			color: #4A4A4A;
			font-weight: normal;
			width: 280px;
			height: 220px;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;
			padding-top: 30px; } } }

.mockup-image {
	.ant-upload.ant-upload-select.ant-upload-select-picture {
		text-align: center;
		.ant-upload {
			text-align: center;
			width: 100%;
			height: 100%;
			display: block;
			i {
				text-align: center;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				right: 0;
				margin: 0 auto;
				visibility: hidden;
				opacity: 0;
				transition: visibility 0.3s, opacity 0.3s linear;
				font-size: 24px;
				color: white;
				width: 50px;
				height: 50px;
				background: $euronics-text-blue;
				padding-top: 12px;
				border-radius: 50%;
				z-index: 6;
				cursor: pointer; } }
		.ant-upload-text {
			display: none; } } }

.mockup:hover {
	.ant-upload.ant-upload-select.ant-upload-select-picture {
		.ant-upload {
			i {
				visibility: visible;
				opacity: 1; } } } }

.location-wrapper {
	position: relative;
	font-size: 16px;
	font-weight: 300;
	margin: 20px 0;
	box-shadow: 0 2px 6px #d4d4d4;
	border-radius: 3px;
	background: white;
	h2 {
		font-weight: 600;
		margin-top: 4px;
		padding-bottom: 10px; }
	.map-cover {
		height: 180px;
		&:after {
			position: absolute;
			opacity: 0;
			content: "";
			width: 100%;
			height: 180px;
			top: 0;
			left: 0;
			background-color: rgba(0, 0, 0, 0.4);
			border-radius: 3px 3px 0 0;
			transition: opacity 200ms linear; } }
	.location-content {
		padding: 15px;
		color: #5E5E5E; }
	.location-radius {
		position: absolute;
		bottom: 14px;
		right: 14px; }
	.location-controls {
		position: absolute;
		height: 0;
		overflow: hidden;
		transition: height 100ms ease-in;
		z-index: 2;
		right: 5px;
		top: 5px;
		padding-top: 5px;
		.control {
			padding-top: 10px;
			margin-right: 10px;
			&.remove-location {
				background: url(https://s3.eu-central-1.amazonaws.com/korner-app/assets/icons/stop_white.svg) no-repeat center 6px;
				background-size: 18px;
				width: 18px;
				height: 26px; } } }
	&:hover {
		.location-controls {
			height: 32px; }
		> .map-cover:after {
			opacity: 1; } } }



.location-search-input {
	box-sizing: border-box;
	margin: 0;
	font-variant: tabular-nums;
	list-style: none;
	-webkit-font-feature-settings: 'tnum';
	font-feature-settings: 'tnum';
	position: relative;
	display: inline-block;
	width: 100%;
	height: 48px;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.65);
	font-size: 14px;
	line-height: 1.5;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	transition: all 0.3s; }

.autocomplete-dropdown-container {
	border: 1px solid $divider-color;
	border-radius: 4px;
	.suggestion-item {
		padding: 5px 10px;
		border-radius: 4px; } }

.map-radius-circle {
	border: 2px solid rgba(255, 0, 0, 0.44);
	background-color: rgba(255, 0, 0, 0.26);
	border-radius: 50%; }

.store-name {
	font-weight: 700;
	font-size: 16px;
	margin-bottom: 10px;
	width: 100%; }

.store-street, .store-phone {
	width: 100%;
	display: block; }

.store-actions {
	text-align: right;
	font-size: 20px;
	i {
		margin-left: 5px; } }

div.summary-list {
	width: 100%;
	margin-top: 30px;
	div.item {
		width: 100%;
		font-size: 13px;
		padding: 15px 0;
		span.title {
			font-weight: bold; }
		span.value {
			float: right; }
		&:not(:last-child) {
			border-bottom: 1px solid #EAEAEA; } } }



// ant design override

.ant-form-item-label {
	label {
		color: $blue-gray-color;
		font-weight: 700;
		font-size: 14px; } }

.ant-steps {
	max-width: 70%;
	margin: 0 auto; }

.ant-calendar-range-picker-separator {
	vertical-align: unset; }

.ant-time-picker {
	width: 100%; }

.ant-time-picker-panel-inner {
	left: 2px;
	top: 50px; }

.ant-calendar-picker {
	display: block; }

.ant-upload-list-picture-card {
	.ant-upload-list-item {
		margin: 0 20px 20px 0; } }

.ant-upload.ant-upload-select-picture-card {
	background: white;
	border: 1px solid $border-color;
	min-width: 155px; }

.has-error .ant-upload.ant-upload-select.ant-upload-select-picture-card {
	border: 1px solid red; }

.upload-icon-wrapper {
	i {
		background: $lightest-gray;
		border-radius: 50%;
		padding: 10px;
		color: white;
		font-size: 32px;
		margin-bottom: 10px; } }

.ant-upload.ant-upload-select-picture-card {
	> .ant-upload {
		padding: 15px; } }

.ant-steps-item-title {
	font-weight: bold;
	line-height: 30px;
	font-size: 16px; }

.ant-steps-item-process {}
.ant-steps-item-title {
	font-weight: bold;
	> .ant-steps-item-content {
		> .ant-steps-item-title {
			color: $euronics-text-blue;
			font-weight: bold; } }
	.ant-steps-item-icon {
		background: white;
		border-color: $euronics-text-blue;
		> .ant-steps-icon {
			color: $euronics-text-blue; } } }

.ant-steps-item-finish {
	.ant-steps-item-icon {
		border-color: $euronics-text-blue;
		> .ant-steps-icon {
			color: $euronics-text-blue; } }
	> .ant-steps-item-tail::after {
		background-color: $euronics-text-blue; } }

.ant-checkbox-inner {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	&::after {
		left: 35%; } }

.gift-form {
	padding-top: 40px; }

.gift-form {
	> div[class*="col-"] {
		margin-top: 15px;
		margin-bottom: 15px; }
	> div[class*="col-"]:first-of-type {
		margin-top: 0; } }

.ant-btn {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	padding-left: 30px;
	padding-right: 30px;
	border-radius: 6px;
	border: none!important; }

.has-error .ant-form-explain, .has-error .ant-form-split {
	font-size: 12px;
	margin-top: 5px;
	font-weight: 600; }

.ant-btn.primary-btn {
	background: $euronics-yellow;
	color: white; }

.ant-btn.secondary-btn {
	background: $light-blue-background;
	color: $euronics-blue; }

.toggle-btn {
	cursor: pointer;
	width: 35px;
	height: 35px;
	border-radius: 6px;
	background: $light-blue-background url('../public/images/chevron-down-solid.svg') no-repeat center center;
	background-size: 12px auto;
	transition: all 200ms ease-in-out; }

.toggle-btn[toggled="toggled"] {
	background-image: url('../public/images/chevron-up-solid.svg'); }

.toggle-btn:hover {
	opacity: 0.8; }

.panel {
	position: relative;
	padding: 20px;
	margin-bottom: 20px;
	border: none;
	background: white;
	border-radius: 8px;
	z-index: 2;
	box-shadow: 12px 12px 20px rgba(219, 225, 232, 0.7);
	.ant-btn {
		span {
			font-size: 12px; } }
	.item-value-group-space-between {
		div {
			padding-left: 20px;
			float: left;
			p {
				font-size: 14px;
				color: #849BBB;
				font-weight: 400;
				margin:	0; }
			span {
				display: block;
				font-size: 20px;
				font-weight: 600;
				color: black; } } }
	.item-value-group {
		padding: 0 25px;
		float: left;
		p {
			font-size: 14px;
			color: #849BBB;
			font-weight: 400;
			margin:	0; }
		span {
			display: block;
			font-size: 20px;
			font-weight: 600;
			color: black; } } }
.ant-radio-wrapper {
	min-width: 70px; }

.ant-tabs {
	overflow: visible; }

.ant-tabs-bar {
	padding-top: 15px;
	position: relative;
	background: white;
	border: none; }

.ant-tabs-bar:before {
	position: absolute;
	content: ' ';
	width: 100vw;
	background: white;
	top: 0;
	height: 100%;
	right: 0; }

.ant-tabs-bar:after {
	position: absolute;
	content: ' ';
	width: 100vw;
	background: white;
	top: 0;
	height: 100%;
	left: 0; }

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
	z-index: 2;
	border: none;
	padding: 0 40px;
	margin-right: unset;
	background: white;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px; }

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
	height: auto; }

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
	color: $euronics-text-blue;
	background: #EFF1F4; }

.ant-table-thead > tr > th {
	background: transparent;
	font-weight: bold;
	color: #a7a7a7; }

.ant-form-item {
	margin-bottom: 10px; }

.ant-form-item-label label {
	color: #101010;
	font-size: 14px;
	font-weight: 600; }

.ant-table-wrapper {
	border-radius: 12px;
	background: white;
	box-shadow: 12px 12px 20px rgba(219, 225, 232, 0.7);
	margin-bottom: 40px; }

.panel + .ant-table-wrapper {
	position: relative;
	margin-top: -20px;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin-left: 20px;
	margin-right: 20px;
	z-index: 1; }

.ant-table-pagination.ant-pagination {
	margin-right: 20px; }

.ant-table-thead > tr > th {
	color: #838383;
	font-weight: 400;
	font-size: 12px;
	background: #FAFAFA; }

.panel + .ant-table-wrapper .ant-table-thead > tr > th {
	padding-top: 30px; }

.panel + .ant-table-wrapper .ant-table-bordered .ant-table-header > table, .ant-table-bordered .ant-table-body > table, .ant-table-bordered .ant-table-fixed-left table, .ant-table-bordered .ant-table-fixed-right table {
	border-left: 0; }

.panel + .ant-table-wrapper .ant-table-bordered .ant-table-thead > tr > th:last-of-type, .ant-table-bordered .ant-table-tbody > tr > td:last-of-type {
	border-right: 0; }

.ant-input-number-handler-wrap {
	border-top-right-radius: 6px; }

.simple-layout {
	min-height: 100vh;
	img {
		width: 100%;
		height: auto; }
	background: #135eaa;
	.ant-form-item {
		margin-bottom: 20px; }
	input.ant-input {
		background: transparent;
		padding: 0;
		color: white;
		border-radius: 0;
		font-size: 16px;
		border-bottom: 1px solid white; }
	.ant-form-item-label {
		label {
			color: white;
			font-weight: bold;
			font-size: 16px; } }
	button.btn {
		font-size: 16px;
		height: 56px;
		width: 100%;
		border: 2px solid #FFFFFF;
		background: transparent;
		color: white;
		&.primary {
			background: white;
			border-radius: 4px;
			color: #1D428A; } } }

.gift-card-mockup {
	background: white;
	border-radius: 40px;
	padding: 20px;
	height: 725px;
	width: 362px;
	.template-background {
		height: 100%;
		border-radius: 28px; }
	.content {
		background: url('../public/images/gift-card-mockup.svg');
		border-radius: 28px;
		background-size: cover;
		height: 100%;
		padding: 15px;
		.branch {
			margin-top: 70px;
			margin-bottom: 30px;
			font-size: 16px;
			width: 100%;
			text-align: right;
			color: #BB972E;
			text-transform: uppercase; }
		.name {
			font-size: 18px;
			color: #2A2A2A;
			margin-bottom: 30px;
			font-weight: bold; }
		.value-wrapper {
			width: 100%;
			.value {
				font-size: 50px;
				color: #1D428A;
				float: left;
				font-weight: bold; }
			.label {
				font-size: 50px;
				float: left;
				color: #1D428A;
				font-weight: bold; }
			.date {
				font-size: 12px;
				color: #1D428A;
				float: right;
				text-align: right;
				height: 100%;
				padding-top: 15px;
				font-weight: bold; } }
		.description {
			margin-top: 30px;
			font-size: 14px;
			color: #616161;
			width: 100%;
			float: left;
			overflow: hidden;
			max-height: 64px; } } }


//loading
.page-loading, .page-failure {
	top: 0;
	left: 0;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	transition: all 200ms ease-in-out;
	&.shown {
		opacity: 1;
		visibility: visible; } }

.page-failure {
	background: white;
	text-align: center;
	h2 {
		font-size: 30px;
		color: black; }
	h4 {
		font-size: 20px;
		color: #A7A9AC; } }

.loader, .loader:before, .loader:after {
	background: $euronics-blue;
	-webkit-animation: load1 1s infinite ease-in-out;
	animation: loading 1s infinite ease-in-out;
	width: 0.5em;
	height: 2em;
	border-radius: 3px; }

.loader {
	color: $euronics-blue;
	text-indent: -9999em;
	margin: 88px auto;
	position: relative;
	font-size: 11px;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
	&:before, &:after {
		position: absolute;
		top: 0;
		content: ''; }
	&:before {
		left: -1.25em;
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s; }
	&:after {
		left: 1.25em; } }

.image-radio-group .ant-form-item-children,
.image-radio-group .ant-radio-group {
	display: block; }


.ant-radio-group-large .ant-radio-button-wrapper.image-radio-btn {
	height: auto;
	text-align: center;
	width: calc(25% - 15px);
	margin-right: 15px;
	border-radius: 7px;
	img {
		margin-top: 10px;
		height: 70px; }
	.image-radio-btn-label {
		display: block;
		font-size: 13px;
		line-height: 1.5;
		margin: 10px 0; } }

.ant-calendar-picker-icon {
	display: none; }

.ant-upload-list-picture-card {
	.ant-upload-list-item {
		min-width: 155px; } }

.ant-calendar-range-right {
	.ant-calendar-date-input-wrap {
		margin-left: 10px; } }

.ant-calendar-range-middle {
	margin-left: 100px; }

.ant-calendar-range .ant-calendar-input, .ant-calendar-range .ant-calendar-time-picker-input {
	text-align: center; }


/* Animations */

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content; }
	@-moz-keyframes #{$name} {
		@content; }
	@-ms-keyframes #{$name} {
		@content; }
	@keyframes #{$name} {
		@content; } }

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg); } }

@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg); } }

@-webkit-keyframes loading {
	0%,	80%, 100% {
		box-shadow: 0 0;
		height: 2em; }

	40% {
		box-shadow: 0 -2em;
		height: 2.5em; } }

@keyframes loading {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 2em; }
	40% {
		box-shadow: 0 -2em;
		height: 2.5em; } }

.notification-warning {
	text-align: right;
	width: 100%;
	font-weight: bold;
	color: $red-color; }

.ant-radio-button-wrapper-checked:focus-within {
	outline: none; }

.invalid-table-row {
	background: #FFF5F5; }
